import React, {useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import Column from '@frontend/ui-kit/Components/Column';
import Heading from '@frontend/ui-kit/Components/Heading';
import Icon from '@frontend/ui-kit/Components/Icon';
import Input from '@frontend/ui-kit/Components/Input';
import Label from '@frontend/ui-kit/Components/Label';
import Row from '@frontend/ui-kit/Components/Row';
import Select from '@frontend/ui-kit/Components/Select';
import Text from '@frontend/ui-kit/Components/Text';
import Checkbox from '@frontend/ui-kit/Components/Checkbox';
import Tooltip from '@frontend/ui-kit/Components/Tooltip';
import {Form, Field} from '../../shared/FormComponents';
import Section from './Section';
import ExternalSftp from '../ExternalSftp';
import {getFormattedDataMapping, validateMaxLength, validateRequired, validateHeaderlessEntries} from '../../../utils';
import {redirectTo} from '../../../actions/general';
import {initImportConfigForm, saveImportConfigForm, saveExternalSftp} from '../../../actions/importConfigs';
import {
    FORMS,
    FORM_GLOBAL_ERROR,
    ROUTES,
    IMPORT_METHOD_OPTIONS,
    ROWS_PER_RECORD_VALUES,
    TPA_DATA_PURPOSES,
    TPA_FILE_TYPES,
    HEADERLESS_TOOLTIP,
    MARKETPLACE_TOOLTIP
} from '../../../constants';
import './index.scss';
import Footer from './Footer';
import Encryption from './Encryption';

const validate = values => ({
    tpa_partner_id: validateRequired(values.tpa_partner_id),
    import_method: validateRequired(values.import_method),
    employer_name: validateHeaderlessEntries(values.is_headerless, values.employer_name),
    member_first_name: validateHeaderlessEntries(values.is_headerless, values.member_first_name),
    member_middle_name: validateHeaderlessEntries(values.is_headerless, values.member_middle_name),
    member_last_name: validateHeaderlessEntries(values.is_headerless, values.member_last_name),
    member_gender: validateHeaderlessEntries(values.is_headerless, values.member_gender),
    address1: validateHeaderlessEntries(values.is_headerless, values.address1),
    address2: validateHeaderlessEntries(values.is_headerless, values.address2),
    city: validateHeaderlessEntries(values.is_headerless, values.city),
    state: validateHeaderlessEntries(values.is_headerless, values.state),
    zip_code: validateHeaderlessEntries(values.is_headerless, values.zip_code),
    member_date_of_birth: validateHeaderlessEntries(values.is_headerless, values.member_date_of_birth),
    social_security_number: validateHeaderlessEntries(values.is_headerless, values.social_security_number),
    last_4_ssn: validateHeaderlessEntries(values.is_headerless, values.last_4_ssn),
    medical_plan_member_id: validateHeaderlessEntries(values.is_headerless, values.medical_plan_member_id),
    email: validateHeaderlessEntries(values.is_headerless, values.email),
    phone_number: validateHeaderlessEntries(values.is_headerless, values.phone_number),
    relationship: validateHeaderlessEntries(values.is_headerless, values.relationship),
    source_member_id: validateHeaderlessEntries(values.is_headerless, values.source_member_id),
    primary_source_member_id: validateHeaderlessEntries(values.is_headerless, values.primary_source_member_id),
    program: validateHeaderlessEntries(values.is_headerless, values.program),
    first_enrollment_date: validateHeaderlessEntries(values.is_headerless, values.first_enrollment_date),
    first_activated_date: validateHeaderlessEntries(values.is_headerless, values.first_activated_date),
    first_device_shipped_date: validateHeaderlessEntries(values.is_headerless, values.first_device_shipped_date),
    first_engaged_date: validateHeaderlessEntries(values.is_headerless, values.first_engaged_date),
    last_engaged_date: validateHeaderlessEntries(values.is_headerless, values.last_engaged_date),
    user_ext_id: validateHeaderlessEntries(values.is_headerless, values.user_ext_id),
    intake_start_date: validateHeaderlessEntries(values.is_headerless, values.intake_start_date),
    intake_end_date: validateHeaderlessEntries(values.is_headerless, values.intake_end_date),
    accepted_date: validateHeaderlessEntries(values.is_headerless, values.accepted_date),
    terminated_date: validateHeaderlessEntries(values.is_headerless, values.terminated_date),
    first_completed_date: validateHeaderlessEntries(values.is_headerless, values.first_completed_date),
    last_completed_date: validateHeaderlessEntries(values.is_headerless, values.last_completed_date),
    internal_notes: validateMaxLength(300, values.internal_notes)
});

const UtilizationEventAutomationForm = ({partnerOptions}) => {
    const dispatch = useDispatch();
    const {id: configId} = useParams();
    const [initialValues, setInitialValues] = useState({});
    const [importConfigId, setImportConfigId] = useState(configId);
    const [importMethod, setImportMethod] = useState();
    const [isEdit, setIsEdit] = useState(!!importConfigId);
    const [isHeaderless, setIsHeaderless] = useState(false);
    const [isMarketplaceIdentifiable, setIsMarketplaceIdentifiable] = useState();
    const [isEncrypted, setIsEncrypted] = useState();
    const [isExternalSftp, setIsExternalSftp] = useState();

    const onSubmit = useCallback(
        async values => {
            const {
                id: configId,
                tpa_partner: tpaPartner,
                tpa_file_type: tpaFileType,
                data_purpose: dataPurpose,
                partnerOptions: partners,
                data_mappings: dataMappings,
                tpa_partner_id: tpaPartnerId,
                import_method: importMethod,
                has_multiple_rows_per_record: rowsPerRecord,
                join_key: joinKey,
                internal_notes: internalNotes,
                is_headerless: isHeaderless,
                is_marketplace_identifiable: isMarketplaceIdentifiable,
                is_encrypted_file: isEncrypted,
                vault_path: vaultPath,
                is_external_sftp: isExternalSftp,
                external_sftp_id: externalSftpId,
                external_sftp: {
                    credentials_link: credentialsLink,
                    directory_path: directoryPath,
                    filename_pattern: filenamePattern,
                    sync_frequency_in_hours: syncFrequencyInHours,
                    last_sync: lastSync
                } = {},
                ...dataMappingsFormValues
            } = values;

            const formDataMappings = Object.entries(dataMappingsFormValues).map(([fieldName, fieldValue]) => ({
                healthjoy_field: fieldName,
                data_field: fieldValue
            }));
            const formattedDataMappings = formDataMappings.map(getFormattedDataMapping).flat();

            const filterOutEmpties = mapping => {
                const {data_field: fieldValue} = mapping;

                return !!fieldValue;
            };

            const validDataMappings = formattedDataMappings.filter(filterOutEmpties);
            const importConfigRequestParams = {
                tpa_file_type: TPA_FILE_TYPES.utilization,
                data_purpose: TPA_DATA_PURPOSES.eventAutomation,
                tpa_partner_id: tpaPartnerId,
                import_method: importMethod,
                join_key: rowsPerRecord === ROWS_PER_RECORD_VALUES.multipleRowsPerRecord ? joinKey : null,
                data_mappings: validDataMappings,
                is_headerless: isHeaderless || false,
                is_marketplace_identifiable: isMarketplaceIdentifiable || false,
                is_encrypted_file: isEncrypted,
                vault_path: vaultPath || null,
                is_external_sftp: importMethod === 'Manual Upload' ? false : isExternalSftp,
                external_sftp_id: externalSftpId || null,
                internal_notes: internalNotes || null
            };

            if (isExternalSftp && importMethod === 'SFTP') {
                const todaysDate = new Date().toISOString();

                const externalSftpRequestParams = {
                    credentials_link: credentialsLink,
                    directory_path: directoryPath || '',
                    filename_pattern: filenamePattern,
                    sync_frequency_in_hours: syncFrequencyInHours,
                    last_sync: lastSync || todaysDate
                };

                const {data, isSuccess, submissionGlobalError} = await dispatch(
                    saveExternalSftp(externalSftpId, {...externalSftpRequestParams})
                );

                if (!isSuccess) {
                    return {[FORM_GLOBAL_ERROR]: submissionGlobalError};
                }

                importConfigRequestParams.external_sftp_id = data.id;
            }

            const {data, isSuccess, isEdit, submissionGlobalError} = await dispatch(
                saveImportConfigForm(configId, {...importConfigRequestParams})
            );
            const {id: importConfigId} = data;

            if (!isSuccess) {
                return {[FORM_GLOBAL_ERROR]: submissionGlobalError};
            }

            setInitialValues(values);
            setIsEdit(isEdit);
            setImportConfigId(importConfigId);
            setIsHeaderless(isHeaderless);
            setIsMarketplaceIdentifiable(isMarketplaceIdentifiable);
        },
        [dispatch]
    );

    useEffect(() => {
        (async () => {
            const {data} = await dispatch(initImportConfigForm(importConfigId));
            const {
                is_encrypted_file: isEncrypted,
                is_external_sftp: isExternalSftp,
                import_method: importMethod
            } = data;

            setInitialValues(data);
            setIsEncrypted(isEncrypted);
            setIsExternalSftp(isExternalSftp);
            setImportMethod(importMethod);
            setIsMarketplaceIdentifiable(isMarketplaceIdentifiable)
        })();
    }, [dispatch, importConfigId]);

    const onSubmitSuccess = useCallback(async () => {
        if (!isEdit) {
            dispatch(redirectTo(`${ROUTES.importConfigs}/${importConfigId}/edit?id=${importConfigId}`));
        }
    }, [dispatch, importConfigId, isEdit]);

    const onImportMethodChange = value => {
        setImportMethod(value);

        if (importMethod === 'Manual Upload') {
            setIsExternalSftp(false);
        }
    };

    const onHeaderlessChange = (event, form) => {
        event.persist();
        setIsHeaderless(!isHeaderless);
        form.change('is_headerless', !isHeaderless);
    };

    const headerlessTooltipText = <Text className='information-paragraph'>{HEADERLESS_TOOLTIP.body}</Text>;

    const headerlessTooltip = (
        <div>
            Headerless
            <Tooltip isClickable content={headerlessTooltipText}>
                <Icon type='info-circle' />
            </Tooltip>
        </div>
    );

    const onMarketplaceIdentifiableChange = (event, form) => {
        event.persist();
        setIsMarketplaceIdentifiable(isMarketplaceIdentifiable => !isMarketplaceIdentifiable);
        // TODO: Why is form null when testing?
        form.change('is_marketplace_identifiable', !isMarketplaceIdentifiable);
        form.change('user_ext_id', null);
        form.change('primary_source_member_id', null);
        form.change('source_member_id', null)
    };

    const marketplaceTooltipText = <Text className='information-paragraph'>{MARKETPLACE_TOOLTIP.body}</Text>;

    const marketplaceTooltip = (
        <div>
            HealthJoy-generated Identifier
            <Tooltip isClickable content={marketplaceTooltipText}>
                <Icon type='info-circle' />
            </Tooltip>
        </div>
    );

    return (
        <div className='import-config-form'>
            <Column offset={0} sm={12}>
                <Form
                    name={FORMS.utilizationImportConfig}
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validate={validate}
                    onSubmitSuccess={onSubmitSuccess}
                >
                    {({handleSubmit, submitError}) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Section header='Data Source' offset={0} sm={12}>
                                    <Row className='import-config-form-upper-margin'>
                                        <Column sm={4}>
                                            <Label label='Source' type='select'>
                                                <Field name='tpa_partner_id'>
                                                    {props => <Select {...props} options={partnerOptions} />}
                                                </Field>
                                            </Label>
                                        </Column>
                                        <Column sm={4}>
                                            <Label label='Import Method' type='select'>
                                                <Field name='import_method' onChange={onImportMethodChange}>
                                                    {props => <Select {...props} options={IMPORT_METHOD_OPTIONS} />}
                                                </Field>
                                            </Label>
                                        </Column>
                                        {importMethod === 'SFTP' && <ExternalSftp isExternalSftp={isExternalSftp} />}
                                        <Encryption isEncrypted={isEncrypted} />
                                    </Row>
                                </Section>
                                <Section header='Data Mapping' offset={0} sm={12} displaySeparator={false}>
                                    <Row className='import-config-form-upper-margin'>
                                        <Column sm={4}>
                                            <Label label={headerlessTooltip} type='checkbox'>
                                                <Field name='is_headerless' onChange={onHeaderlessChange}>
                                                    {props => <Checkbox {...props} />}
                                                </Field>
                                            </Label>
                                        </Column>
                                        <Column sm={4}>
                                            <Label label={marketplaceTooltip} type='checkbox'>
                                                <Field name='is_marketplace_identifiable'>
                                                    {props => <Checkbox {...props}  data-testid='is_marketplace_identifiable_checkbox' onChange={onMarketplaceIdentifiableChange}/>}
                                                </Field>
                                            </Label>
                                        </Column>
                                    </Row>
                                    <Heading
                                        gradation={6}
                                        fontWeight='semibold'
                                        className='import-config-form-section-heading'
                                    >
                                        Member Information
                                    </Heading>
                                    <Section offset={0} sm={12} displaySeparator={false}>
                                        <Row>
                                            <Column sm={4}>
                                                <Label label='Auth Ext ID' type='input'>
                                                    <Field name='user_ext_id'>{props => <Input {...props} disabled={isMarketplaceIdentifiable}/>}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Member First Name' type='input'>
                                                    <Field name='member_first_name'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Member Middle Name' type='input'>
                                                    <Field name='member_middle_name'>{props => <Input {...props}/>}</Field>
                                                </Label>
                                            </Column>
                                        </Row>
                                    </Section>
                                    <Section offset={0} sm={12} displaySeparator={false}>
                                        <Row>
                                            <Column sm={4}>
                                                <Label label='Member Last Name' type='input'>
                                                    <Field name='member_last_name'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Member Gender' type='input'>
                                                    <Field name='member_gender'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Address 1' type='input'>
                                                    <Field name='address1'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                        </Row>
                                        <Row>
                                            <Column sm={4}>
                                                <Label label='Address 2' type='input'>
                                                    <Field name='address2'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='City' type='input'>
                                                    <Field name='city'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='State' type='input'>
                                                    <Field name='state'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                        </Row>
                                        <Row>
                                            <Column sm={4}>
                                                <Label label='ZIP Code' type='input'>
                                                    <Field name='zip_code'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Member Date Of Birth' type='input'>
                                                    <Field name='member_date_of_birth'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Social Security Number' type='input'>
                                                    <Field name='social_security_number'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                        </Row>
                                    </Section>
                                    <Section offset={0} sm={12} displaySeparator={false}>
                                        <Row>
                                            <Column sm={4}>
                                                <Label label='Last 4 SSN' type='input'>
                                                    <Field name='last_4_ssn'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Medical Plan Member ID' type='input'>
                                                    <Field name='medical_plan_member_id'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Email' type='input'>
                                                    <Field name='email'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                        </Row>
                                        <Row>
                                            <Column sm={4}>
                                                <Label label='Phone Number' type='input'>
                                                    <Field name='phone_number'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Relationship' type='input'>
                                                    <Field name='relationship'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Employer Name' type='input'>
                                                    <Field name='employer_name'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                        </Row>
                                        <Row>
                                            <Column sm={4}>
                                                <Label label='HealthJoy Primary Source Member ID' type='input'>
                                                    <Field name='primary_source_member_id' >{props => <Input disabled={!isMarketplaceIdentifiable} {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='HealthJoy Source Member ID' type='input'>
                                                    <Field name='source_member_id'>
                                                        {props => <Input disabled={!isMarketplaceIdentifiable} {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                        </Row>
                                    </Section>
                                    <Heading
                                        gradation={6}
                                        fontWeight='semibold'
                                        className='import-config-form-section-heading'
                                    >
                                        Program Activity
                                    </Heading>
                                    <Section offset={0} sm={12} displaySeparator={false}>
                                        <Row>
                                            <Column sm={4}>
                                                <Label label='Program' type='input'>
                                                    <Field name='program'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='First Enrollment Date' type='input'>
                                                    <Field name='first_enrollment_date'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='First Activated Date' type='input'>
                                                    <Field name='first_activated_date'>
                                                        {props => <Input {...props} />}
                                                    </Field>
                                                </Label>
                                            </Column>
                                        </Row>
                                        <Row>
                                            <Column sm={4}>
                                                <Label label='First Device Shipped Date' type='input'>
                                                    <Field name='first_device_shipped_date'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='First Engaged Date' type='input'>
                                                    <Field name='first_engaged_date'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Last Engaged Date' type='input'>
                                                    <Field name='last_engaged_date'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                        </Row>
                                        <Row>
                                            <Column sm={4}>
                                                <Label label='Intake Start Date' type='input'>
                                                    <Field name='intake_start_date'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Intake End Date' type='input'>
                                                    <Field name='intake_end_date'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='First Completed Date' type='input'>
                                                    <Field name='first_completed_date'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                        </Row>
                                        <Row>
                                            <Column sm={4}>
                                                <Label label='Last Completed Date' type='input'>
                                                    <Field name='last_completed_date'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Accepted Date' type='input'>
                                                    <Field name='accepted_date'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                            <Column sm={4}>
                                                <Label label='Terminated Date' type='input'>
                                                    <Field name='terminated_date'>{props => <Input {...props} />}</Field>
                                                </Label>
                                            </Column>
                                        </Row>
                                    </Section>
                                </Section>
                                <Footer importConfigId={importConfigId} submitError={submitError} />
                            </form>
                        );
                    }}
                </Form>
            </Column>
        </div>
    );
};

UtilizationEventAutomationForm.propTypes = {
    partnerOptions: PropTypes.arrayOf(PropTypes.shape({label: PropTypes.string, value: PropTypes.number}))
};

export {UtilizationEventAutomationForm as TestableUtilizationEventAutomationForm};
export default React.memo(UtilizationEventAutomationForm);
